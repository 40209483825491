import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './Login.js';
import IntraDaySmallTable from './IntraDaySmallTable.js';
import IntraDayMidLargeTable from './IntraDayMidLargeTable.js';
import PreMarketTable from './PreMarketTable.js';
import ProtectedRoute from './ProtectedRoute.js';
import BrokerTrend from './BrokerTrend.js';
import NavBar from './NavBar.js';
import { AuthProvider } from './AuthContext';

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <NavBar />
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/intra-day-small" element={<ProtectedRoute element={IntraDaySmallTable} />} />
                    <Route path="/intra-day-mid-large" element={<ProtectedRoute element={IntraDayMidLargeTable} />} />
                    <Route path="/pre-market" element={<ProtectedRoute element={PreMarketTable} />} />
                    <Route path="/broker-trend" element={<ProtectedRoute element={BrokerTrend} />} />
                    <Route path="/" element={<Login />} />
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;

import React, { useState } from 'react';
import './styles.css';
import Table from './Table.js';

const refreshCountdown = 10;
const fetchFunction = '/intradata';
const defaultSort = 'pips';
const title = 'Top Movers under $1';

const IntraDaySmallTable = () => {
    const [today, setToday] = useState(new Date().toLocaleDateString('en-au'));

    const transformLogic = (data) => {
        return Object.values(data).filter(
            (item) => item.trend.lastActive === today && item.open < 1 && (item.change >= 0 || item.close > item.VWAP)
        );
    };

    const scannerColumnConfig = [
        {
            name: 'Name',
            field: 'name',
            sortable: true,
            calcValue: (item) => {
                const hasOtherNews =
                    item.news &&
                    item.news[today] &&
                    Object.keys(item.news[today]).filter((key) => key !== 'summary' && key !== 'alerted').length > 0;
                return (hasOtherNews ? '📄 ' : '') + item.name;
            },
            class: 'name-column'
        },
        { name: 'Change', field: 'change', format: 'percent', digits: 1, sortable: true },
        {
            name: 'Last',
            field: 'close',
            format: 'currency',
            digits: 3,
            sortable: true,
            animationColor: true
        },
        { name: 'Pips', field: 'pips', sortable: true },
        {
            name: 'VWAP',
            comparitor: (item) => item.close,
            format: 'currency',
            digits: 3,
            conditionalColor: { positiveColor: '#ff0000', negativeColor: '#00ff00' }
        },
        { name: 'Open', field: 'open', format: 'currency', digits: 3 },
        { name: 'High', field: 'high', format: 'currency', digits: 3, animationColor: true },
        { name: 'Low', field: 'low', format: 'currency', digits: 3, animationColor: true },
        {
            name: '$ Traded',
            field: 'Value.Traded',
            format: 'currency',
            digits: 0,
            sortable: true
        }
    ];

    return (
        <div>
            <Table
                title={title}
                refreshCountdown={refreshCountdown}
                columnConfig={scannerColumnConfig}
                fetchFunction={fetchFunction}
                defaultSort={defaultSort}
                transformLogic={transformLogic}
                setTodayParent={setToday}
            />
        </div>
    );
};

export default IntraDaySmallTable;

import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ initialCount, onTimeout }) => {
    const [countdown, setCountdown] = useState(initialCount);

    // Call onTimeout immediately when the component mounts.
    useEffect(() => {
        if (onTimeout) onTimeout();
        // We intentionally leave the dependency array empty so that this runs only once on mount.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set up the countdown interval.
    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prev) => {
                if (prev <= 1) {
                    if (onTimeout) onTimeout();
                    return initialCount;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [initialCount, onTimeout]);

    return <div id="refresh-timer">Refresh: {countdown}s</div>;
};

export default CountdownTimer;

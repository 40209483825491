import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    // Initialize the token from sessionStorage
    const [accessToken, setAccessToken] = useState(() => {
        return sessionStorage.getItem('accessToken') || null;
    });

    const login = (token) => {
        sessionStorage.setItem('accessToken', token);
        setAccessToken(token);
    };

    const logout = () => {
        sessionStorage.removeItem('accessToken');
        setAccessToken(null);
    };

    const isAuthenticated = Boolean(accessToken);

    return (
        <AuthContext.Provider value={{ accessToken, login, logout, isAuthenticated }}>{children}</AuthContext.Provider>
    );
};

// Custom hook for easy usage of the context
export const useAuth = () => useContext(AuthContext);
